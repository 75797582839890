import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {useWidgetState} from '../../../hooks/state-provider'
import {useAdditionalItemsToggle} from '../../../hooks/use-additional-items-toggle'
import {getComponentConfig, isListLayout} from '../../../selectors/settings'
import {ListItemContainerProps} from './interfaces'
import s from './list-item-container.scss'

export const ListItemContainer = ({event, mobile, cardDivider, hideDivider, children}: ListItemContainerProps) => {
  const listLayout = useWidgetState(state => isListLayout(getComponentConfig(state)))
  const {opened, hovered, events: toggleEvents} = useAdditionalItemsToggle({event})

  return (
    <li
      {...toggleEvents}
      data-hook={DH.listItem}
      className={classNames(s.container, mobile && !listLayout ? s.cardBorderColor : s.listDividerColor, {
        [s.listHoveredBorderColor]: listLayout && (hovered || opened) && !mobile,
        [s.cardDivider]: cardDivider,
        [s.hideDivider]: hideDivider,
      })}
    >
      {children(hovered)}
    </li>
  )
}
