import classNames from 'classnames'
import React from 'react'
import {Members} from '../../../members'
import {DescriptionProps} from './interfaces'
import s from './members.scss'

export const ListItemMembers: React.FC<DescriptionProps> = ({event, visible, mobile, onClick}: DescriptionProps) =>
  visible ? (
    <div className={classNames(s.members, {[s.mobile]: mobile})} data-hook="ev-list-item-members" onClick={onClick}>
      <Members event={event} listLayout />
    </div>
  ) : null
