import classNames from 'classnames'
import React from 'react'
import {Animated} from '../../../../../../commons/components/animated'
import classesV2 from '../../classes-v2.scss'
import {FullDateLocation} from '../../full-date-location'
import {Ribbon} from '../../ribbon'
import {RsvpButton} from '../../rsvp-button'
import {Date} from './date'
import {Description} from './description'
import s from './desktop-item.scss'
import {Image} from './image'
import {Members} from './members'
import {Share} from './share'
import {Title} from './title'
import {TitleLocation} from './title-location'
import {ItemProps, TitleComponentProps} from '.'

export const DesktopItem = ({
  t,
  event,
  opened,
  hovered,
  showRibbon,
  showMembers,
  isSite,
  scheduleTbd,
  rtl,
}: ItemProps) => {
  const active = opened || hovered

  return (
    <>
      <div className={classNames(s.collapsedContainer)}>
        <div className={s.leftPositioning}>
          <div className={s.left}>
            <div className={classNames(s.imageContainer, {[s.collapsed]: active, [s.ltr]: !rtl})}>
              <div className={s.image}>
                <Image event={event} />
              </div>
            </div>
            <a className={s.titleAndDateContainer} tabIndex={0} aria-expanded={opened} role="button">
              {!active && <Date scheduleTbd={scheduleTbd} event={event} />}
              <TitleComponent event={event} hovered={hovered} opened={opened} />
            </a>
          </div>
        </div>
        <div className={s.button}>
          {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
          <RsvpButton event={event} minWidth={100} maxWidth={160} />
        </div>
      </div>
      <div className={s.additionalInfo}>
        <Animated disabled={!isSite} height={calculateContentHeight} show={opened}>
          <div className={classNames(s.textContent, s.listContentText)}>
            <FullDateLocation
              event={event}
              dateClassName={classesV2.hoverEventDate}
              locationClassName={classesV2.hoverEventLocation}
            />
            <Members event={event} visible={showMembers} />
            <Description event={event} />
            <Share t={t} event={event} />
          </div>
        </Animated>
      </div>
    </>
  )
}

const TitleComponent = ({event, hovered, opened}: TitleComponentProps) =>
  hovered || opened ? <Title event={event} opened={opened} withOverflow={!opened} /> : <TitleLocation event={event} />

const calculateContentHeight = (element: HTMLElement) => element.offsetHeight + 60
