import {KeyboardEvent, useState} from 'react'
import {isItemOpened} from '../selectors/layout'
import {useWidgetActions, useWidgetState} from './state-provider'
import {useVisibilityStyles} from './use-visibility-styles'

export const useAdditionalItemsToggle = ({event}: {event: ExtendedEvent}) => {
  const {openListLayoutItem, closeListLayoutItems} = useWidgetActions()
  const {currentBreakpoint} = useVisibilityStyles()

  const [hovered, setHovered] = useState(false)
  const opened = useWidgetState(state => isItemOpened(state, event.id))

  const onKeyPress = (e: KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter') {
      toggle(e)
    }
  }

  const onMouseEnter = () => {
    setHovered(true)
  }

  const onMouseLeave = () => {
    setHovered(false)
  }

  const toggle = e => {
    if (e.target.getAttribute('data-hook') === 'ev-rsvp-button') {
      return
    }

    if (opened) {
      closeListLayoutItems()
    } else if (currentBreakpoint.isListAdditionalComponentsVisible()) {
      openListLayoutItem(event.id)
    }
    e.stopPropagation()
  }

  return {
    hovered,
    opened,
    events: {
      onKeyPress,
      onMouseEnter,
      onMouseLeave,
      onClick: toggle,
    },
  }
}
