import React from 'react'
import {Animator, AnimatorProps} from 'wix-animations/dist/src'
import s from './animated.scss'

interface AnimatedProps {
  disabled: boolean
  show: boolean
}

export const Animated: React.FunctionComponent<AnimatedProps & AnimatorProps> = ({
  disabled,
  show,
  children,
  ...rest
}) => {
  if (disabled) {
    return <div className={show ? null : s.invisible}>{children}</div>
  }
  return (
    <Animator {...rest}>
      {show && children}
      {!show && <div className={s.invisible}>{children}</div>}
    </Animator>
  )
}
