import React from 'react'
import {SocialBar} from '../../../social-bar'
import s from './share.scss'
import {ShareProps} from '.'

export const Share = ({t, event}: ShareProps) => (
  <div className={s.container}>
    <SocialBar t={t} spacing={24} event={event} row />
  </div>
)
