import classNames from 'classnames'
import React from 'react'
import s from './title-location.scss'

interface TitleProps {
  event: wix.events.Event
}

export const TitleLocation = ({event}: TitleProps) => (
  <div className={s.container}>
    <div className={s.title} data-hook="ev-list-item-title">
      {event.title}
    </div>
    <div className={classNames(s.title, s.locationVisibility)} aria-hidden="true">
      &nbsp;/&nbsp;
    </div>
    <div className={s.location} data-hook="ev-list-item-location">
      {event.location.name || event.location.address}
    </div>
  </div>
)
